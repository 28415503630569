import { SERVICE_DETAIL_URL } from "../../components/graphql"
import { useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import Login from "../../components/login"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"
import '../../assets/css/services.css';
import faqarrow from '../../assets/images/faq-arrow.png';
import $ from "jquery"

const ServiceDetailsPage = service_detail_content => {
  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [userId, setUserID] = useState(undefined)
  const [phoneError, setphoneError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  var locationRef = service_detail_content.location.href
  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
        $(document).ready(function () {
          $('.div-all').hide();
          $(".div-1").show();
          $( ".faqQuestionImage" ).each(function( index ) {
            $( this ).removeClass('active');
          });
          $(".arrow-1").addClass('active');
        });
        $(".link").on('click', function () {
            console.log("calklj",$(this).attr('data-ids'))
            var value = $(this).attr('data-ids')
            $('.div-all').hide();
            $(".div-" + value).show();
            $( ".faqQuestionImage" ).each(function( index ) {
              $( this ).removeClass('active');
            });
            $(".arrow-" + value).addClass('active');
        });
    }
  }
  });
  var serviceDetail
  if (locationRef) {
    if (locationRef.slice(-1) === "/") {
      locationRef = locationRef.slice(0, -1)
    }
  }
  var lastContent = undefined
  var strippedContent = undefined

  if (locationRef) {
    lastContent = locationRef.substring(locationRef.lastIndexOf("/") + 1)

    strippedContent = lastContent.substring(
      0,
      lastContent.indexOf("#") + "#".length - 1
    )

    if (strippedContent === "") {
      strippedContent = lastContent
    }
  }

  const slug = strippedContent;

  const serviceDetailContent = useQuery(SERVICE_DETAIL_URL, {
    variables: { url: slug },
  })
  if (serviceDetailContent) {
    serviceDetail = serviceDetailContent?.data?.servicesCategoryPages?.[0]
  }

  if (!slug || slug === "null" || slug === "undefined") {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      window.location.replace("/universities")
    }
  }

  return (
    <Layout setTitle="Services" tag="services">
      <Login
        open={open}
        setOpen={setOpen}
        login={login}
        setLogin={setLogin}
        setUsername={setUsername}
        setUserID={setUserID}
        forgotPassword={forgotPassword}
        setForgotPassword={setForgotPassword}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
        phoneError={phoneError}
        setphoneError={setphoneError}
      />
      <div className="main-content">
        {serviceDetail && (
          <div className="services-section detail">
            {(serviceDetail?.title ||
              serviceDetail?.description ||
              serviceDetail?.image?.url) && (
                <div className="fullwidth-bg">
                  <div className="container">
                    <div className="serviceContent">
                      <div className="service-container">
                        {serviceDetail?.title && (
                          <div className="title blue serviceTitle">{serviceDetail?.title}</div>
                        )}
                        {serviceDetail?.description && (
                          <div className="serviceDescription">
                            <ReactMarkdown
                              source={serviceDetail?.description}
                              transformImageUri={uri =>
                                uri.startsWith("http")
                                  ? uri
                                  : `${process.env.BACKEND_URL}${uri}`
                              }
                          />
                          </div>
                        )}
                      </div>
                      {serviceDetail?.image?.url && (
                        <div className="serviceImage image">
                          <img
                            src={
                              serviceDetail?.image?.url
                                ? process.env.BACKEND_URL + serviceDetail?.image?.url
                                : ""
                            }
                            alt="imageSection"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            <div className="container">
              {serviceDetail?.leftSideDescription && (
                <div className="serviceDetails">
                  {serviceDetail?.leftSideDescription && (
                    <div className="serviceDetails-left">
                      <ReactMarkdown
                        source={serviceDetail?.leftSideDescription}
                        className="serviceDetails-leftdescription"
                        transformImageUri={uri =>
                          uri.startsWith("http")
                            ? uri
                            : `${process.env.BACKEND_URL}${uri}`
                        }
                      />
                    </div>
                  )}
                  {serviceDetail?.rightSideDescription && (
                    <div className="serviceDetails-right">
                      {serviceDetail?.rightSideDescriptionTitle && (
                        <div className="serviceDetails-rightdescription title blue">
                          {serviceDetail?.rightSideDescriptionTitle}
                        </div>
                      )}
                      {serviceDetail?.rightSideDescription && (
                        <div className="serviceDetails_right_description">
                          <ReactMarkdown
                              source={serviceDetail?.rightSideDescription}
                              transformImageUri={uri =>
                                uri.startsWith("http")
                                  ? uri
                                  : `${process.env.BACKEND_URL}${uri}`
                              }
                          />
                        </div>
                      )}
                      {serviceDetail?.rightSideDescriptionButtonType &&
                        serviceDetail?.rightSideDescriptionButtonType == "Custom" ? (
                        <div className="customServiceButton">
                          <button onClick={() => { }}>
                            {serviceDetail?.rightSideDescriptionButtonName}
                          </button>
                        </div>
                      ) : (
                        <div className="urlServiceButton cta-primary">
                          {serviceDetail?.rightsideDescriptionButtonURL &&
                            serviceDetail?.rightsideDescriptionButtonURL
                              ?.toString()
                              ?.startsWith("/") ? (
                            <Link
                              to={
                                process.env.GATSBY_URL +
                                serviceDetail?.rightsideDescriptionButtonURL
                              }
                            >
                              {serviceDetail?.rightSideDescriptionButtonName}
                            </Link>
                          ) : (
                            <Link to={serviceDetail?.rightsideDescriptionButtonURL}>
                              {serviceDetail?.rightSideDescriptionButtonName}
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  

                </div>
              )}
              {(serviceDetail?.secondSectionTitle ||
                      serviceDetail?.secondSectionDescription) && 
                <div className="serviceSecondSection serviceContent">
                  {serviceDetail?.secondSectionTitle && (
                    <div className="serviceSecondSection-left service-container">
                      {(serviceDetail?.secondSectionTitle ||
                        serviceDetail?.secondSectionDescription) && (
                          <div className="secondSectionTitle title blue">
                            {serviceDetail?.secondSectionTitle}
                          </div>
                        )}
                      {serviceDetail?.secondSectionDescription && (
                        <div className="secondSectionDescription">
                          <ReactMarkdown
                                source={serviceDetail?.secondSectionDescription}
                                transformImageUri={uri =>
                                  uri.startsWith("http")
                                    ? uri
                                    : `${process.env.BACKEND_URL}${uri}`
                                }
                            />
                        </div>
                      )}
                      {serviceDetail?.secondSectionButtonName && (
                        <div className="secondSectionButtonName">
                          <Link to="/pricing" className="button">
                            {serviceDetail?.secondSectionButtonName}
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                  {serviceDetail?.secondSectionImage?.url && (
                    <div className="serviceSecondSection-right image">
                      <img
                        className="serviceSecondSectionImage"
                        alt="sectionImage"
                        src={
                          serviceDetail?.secondSectionImage?.url
                            ? process.env.BACKEND_URL +
                            serviceDetail?.secondSectionImage?.url
                            : ""
                        }
                      />
                    </div>
                  )}
                </div>
              }
              <div className="thirdServiceSection">
                <div className="text-center">
                  <div className="title blue">{serviceDetail?.thirdSectionTitle}</div>
                  <div className="desc">
                    <ReactMarkdown
                        source={serviceDetail?.thirdSectionDescription}
                        transformImageUri={uri =>
                          uri.startsWith("http")
                            ? uri
                            : `${process.env.BACKEND_URL}${uri}`
                        }
                    />
                  </div>
                </div>
                <div className="servicesGrid">
                  {serviceDetail?.servicesCategoryContent?.length > 0 && serviceDetail?.servicesCategoryContent?.map((category, index) => (
                    <><div className="servicesGrid-item">
                      <div key={category?.id} className='serviceGrid'>
                        <Link to={(`/services/${category?.url}`) ?? "/"}>
                          <div className='serviceGridTitle'>{category?.mainPageTitle}</div>
                        </Link>
                        <div className='mainPageDescription'>
                          <ReactMarkdown
                            source={category?.mainPageDescription}
                            transformImageUri={uri =>
                              uri.startsWith("http")
                                ? uri
                                : `${process.env.BACKEND_URL}${uri}`
                            }
                          />
                        </div>
                      </div>

                    </div>{(serviceDetail?.servicesCategoryContent?.length ==
                      index + 1) && (
                        <div className='servicesGrid-item serviceExplore'>
                          <div className='firstSectionExtraContentTitle blue'>
                            {serviceDetail?.categoryExtraContentTitle}
                          </div>
                          <div className='firstSectionExtraContentDescription'>
                            <ReactMarkdown
                            source= {serviceDetail?.categoryExtraContentDescription}
                            transformImageUri={uri =>
                              uri.startsWith("http")
                                ? uri
                                : `${process.env.BACKEND_URL}${uri}`
                            }
                            />
                          </div>
                          { serviceDetail?.categoryExtraContentButtonName?
                          <div className="cta-secondary">
                            <Link to='/pricing' className='firstSectionExtraContentButtonName'>
                              {
                                serviceDetail?.categoryExtraContentButtonName
                              }
                            </Link>
                          </div>
                          :null}
                        </div>
                      )}</>

                  ))}
                </div>
              </div>
              {serviceDetail?.faqSectionTitle && <div className='faqSection'>
                <div className='faqSectionHeader'>

                  <img src={serviceDetail?.faqSectionImage?.url
                    ? process.env.BACKEND_URL + serviceDetail?.faqSectionImage?.url
                    : ''} alt="faqimage" className='faqSectionImage' />

                  <div className='faqSectionTitle'>
                    {serviceDetail?.faqSectionTitle}
                  </div>
                </div>
                {serviceDetail?.faqSectionContent?.length > 0 && <div className='faqListContainer'>
                  {serviceDetail?.faqSectionContent?.map((faq, index) => 
                  <div className='faqContent link' key={index}  data-ids={(index + 1)?.toString()}>
                    <div className="faq-ques">
                    <div className={`faqQuestionImage arrow-${(index + 1)?.toString()}`}>
                      <img src={faqarrow} alt="faqarrow" />
                      {/* <i className="fa fa-solid fa-down increaseQty" style={{width:20,height:20,background:'red'}}></i> */}
                    </div>

                    <a className="link">{faq?.questionTitle}</a>
                    </div>
                    <div className={`div-all div-${(index + 1)?.toString()}`}>
                      <ReactMarkdown
                              source={faq?.questionAnswerDescription}
                              transformImageUri={uri =>
                                uri.startsWith("http")
                                  ? uri
                                  : `${process.env.BACKEND_URL}${uri}`
                              }
                          />
                    </div>
                  </div>
                  )}
                </div>}
              </div>}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default ServiceDetailsPage
